<template>
  <div id="app">
    <navigation></navigation>
    <rooms-data></rooms-data>
    <router-view></router-view>
    <register @onCloseOverlay="closeOverlay"></register>
    <login @onCloseOverlay="closeOverlay"></login>
    <profile></profile>
    <forgot @onCloseOverlay="closeOverlay"></forgot>
  </div>
</template>
<script>
/* eslint no-undef: 0 */

import M from 'materialize-css'
import Navigation from '@/views/components/Navigation'
import RoomsData from '@/components/rooms/RoomsData'
import countries from './assets/js/countries'
import Register from '@/components/auth/Register'
import Login from '@/components/auth/Login'
import Profile from '@/components/auth/Profile'
import Forgot from '@/components/auth/Forgot'

export default {
  name: 'App',
  data () {
    return {
      //
    }
  },
  components: {
    Navigation,
    RoomsData,
    Register,
    Login,
    Profile,
    Forgot
  },

  mounted () {
    M.AutoInit()
    // document.addEventListener('DOMContentLoaded', function () {
    //   M.AutoInit()
    // })
    this.$store.state['auth/countries'] = countries

    $('#app').click(function () {
      $('.sidenav').sidenav('close')
      $('.sidenav-overlay').css('display', 'none')
    })
  },
  methods: {
    closeOverlay () {
      $('.sidenav').sidenav('close')
      $('.sidenav-overlay').css('display', 'none')
    }
  }
}
</script>

<style lang="scss">

</style>
