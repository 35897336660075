<template>
  <div id="modal-login" class="modal">
    <div class="modal-content">
      <h4 class="grey-text text-darken-2">Bonn Apartments</h4>
      <form id="login-form" role="form" @submit.prevent="loginUser">
        <div class="input-field">
          <input type="email" id="login-email" class="validate" v-model="email" required/>
          <label for="login-email">Email</label>
        </div>
        <div class="input-field">
          <input type="password" id="login-password" class="validate" v-model="password" required/>
          <label for="login-password" data-error="Please enter your password.">{{ $t('nav.password') }}</label>
        </div>
        <button type="submit" class="btn orange lighten-2 z-depth-0">{{ $t('nav.login') }}</button><span class="transparent-text">&nbsp;&nbsp;</span>
        <a id="close-login" class="modal-action modal-close btn grey z-depth-0" @click="closeOverlay">
          <i class="fa fa-lock"></i> {{ $t('cancel') }}
        </a>
        <a class="transparent right z-depth-0 orange-text waves-effect waves-black modal-trigger"
           data-target="modal-forgot" style="font-size: 0.8rem;padding: 10px 0"
        >
          {{ forgot_password }}
        </a>
      </form>
    </div>
  </div>
</template>

<script>
/* eslint no-undef: 0 */

export default {
  data () {
    return {
      email: '',
      password: '',
      user_id: null,
      error: '',
      auth_method: 'email',
      forgot_password: this.$i18n.t('nav.forgot_password')
    }
  },
  components: {
    //
  },

  mounted () {
    this.$nextTick(() => {
      $('#modal-login').modal({
        inDuration: 500,
        outDuration: 500
      })
    })
  },

  methods: {
    closeOverlay () {
      // $('.sidenav').sidenav('close')
      this.$emit('onCloseOverlay')
    },
    async getUserProfile () {
      await this.$store.dispatch('auth/getUserProfile', this.user_id).then(() => {
        // console.log('Login Profile loaded: ' + this.user_id)
        // alert(this.user_id)
        // let userRole = this.$store.getters['auth/userRole']
        // alert(userRole)
        // document.location.reload()
        // use true to clear site cache
        document.location.reload(true)
      })
    },
    async loginUser () {
      const user = new URLSearchParams()
      const modal = document.querySelector('#modal-login')

      user.append('login', this.email)
      user.append('password', this.password)

      await this.$store.dispatch('auth/loginUser', user).then((response) => {
        this.user_id = response.data.user.id
        // console.log('LOGIN: ' + this.user_id)
        this.getUserProfile()
        this.error = ''
        M.toast({ html: this.$i18n.t('nav.login_success'), classes: 'rounded orange-text' })
        M.Modal.getInstance(modal).close()
      }, error => {
        this.error = error.response.data.error
        if (this.error === 'invalid_credentials') {
          alert(this.$i18n.t('nav.invalid_credentials'))
        }
        // console.log('Login1: ' + error)
      })
    }
  },

  updated () {
    // console.log('Login updated')
  }
}
</script>

<style scoped>
  [type="radio"]:checked + span:after,
  [type="radio"].with-gap:checked + span:before,
  [type="radio"].with-gap:checked + span:after {
    border: 2px solid #ffb74d;
  }
  [type="radio"]:checked + span:after,
  [type="radio"].with-gap:checked + span:after {
    background-color: #ffb74d;
  }
  [type="radio"]:not(:checked) + span, [type="radio"]:checked + span {
    padding-left: 28px;
    margin-bottom: 10px;
  }
</style>
