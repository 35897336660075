<template>
  <nav id="navigation" class="grey darken-3 z-depth-0">
    <div class="navigation">
      <div class="nav-wrapper">
        <a href="\" class="brand-logo bonn-apartments orange-text text-lighten-2 z-depth-0 flow-text" title="to main page ...">
          <img src="@/assets/logo.jpg" alt="" class="my-auto" style="width: 40px; vertical-align: middle" />&nbsp;
          <span class="hide-on-small-only">In Bonn</span>
          <span class="hide-on-med-and-down">- Muster -</span>
        </a>
        <ul class="right">
          <li v-show="authenticated" class="hide-on-med-and-down">
            <a class="transparent z-depth-0 orange-text waves-effect waves-black"
               @click="logout">{{ $t('nav.logout') }}</a>
          </li>
          <li>
            <a :href="`/${$i18n.locale}/location`"
               class="tooltipped"
               data-position="bottom" data-tooltip="Geo Location"
            >
              <i class="material-icons orange-text text-lighten-2">map</i>
            </a>
          </li>
          <li>
            <ChatStarter></ChatStarter>
          </li>
          <li>
            <TopDown></TopDown>
          </li>
          <li class="hide-on-med-and-down">
            <ToggleFullscreen></ToggleFullscreen>
          </li>
          <li>
            <MenuIcon></MenuIcon>
          </li>
        </ul>
        <!-- Side Navigation -->
        <ul class="sidenav grey darken-4 z-depth-0" id="mobile-links">
          <li v-show="authenticated">
            <div class="user-view">
              <div class="background">
                <img src="@/assets/images/sidenav.jpg" alt="">
              </div>
              <a>
                <i class="material-icons left orange-text text-lighten-2">person</i>
              </a>
              <a v-if="authenticated"
                 class="transparent z-depth-0 orange-text waves-effect waves-black modal-trigger tooltipped"
                 data-position="right" :data-tooltip="$t('nav.edit_profile')"
                 data-target="modal-profile">
                {{ user.username }}
              </a>
              <br>
              <br>
            </div>
          </li>
          <li v-show="!authenticated">
            <div class="user-view">
              <div class="background">
                <img src="@/assets/images/sidenav.jpg" alt="">
              </div>
              <a>
                <i class="material-icons left orange-text text-lighten-2">person</i>
              </a>
              <a
                @click="setRegisterUserRole('landlord')"
                class="transparent z-depth-0 orange-text waves-effect waves-black modal-trigger"
                 data-target="modal-register">
                {{ $t('nav.register_landlord') }}
              </a>
              <br>
              <br>
            </div>
          </li>
          <li v-show="!authenticated">
            <a class="transparent z-depth-0 orange-text waves-effect waves-black tooltipped modal-trigger"
               data-position="bottom" :data-tooltip="$t('nav.register_first')"
               data-target="modal-login">
              {{ $t('nav.login') }}
            </a>
          </li>
          <li v-show="!authenticated">
            <a @click="setRegisterUserRole('renter')" class="transparent z-depth-0 orange-text tooltipped waves-effect waves-black modal-trigger"
               data-position="bottom" :data-tooltip="$t('nav.register_renter')"
               data-target="modal-register">
              {{ $t('nav.register') }}
            </a>
          </li>
          <li v-show="authenticated">
            <a class="orange-text"
               @click="logout">{{ $t('nav.logout') }}
            </a>
          </li>
          <li v-show="authenticated && (user_role === 'landlord')">
            <a href="#" class="orange-text"
               >Reports
            </a>
          </li>
          <li v-show="authenticated && (user_role === 'landlord')">
            <a href="#" class="orange-text"
               >Customer notification
            </a>
          </li>
        </ul>
      </div><!-- .nav-wrapper -->
      <ul class="language-switcher">
        <LanguageSwitcher></LanguageSwitcher>
      </ul>
    </div><!-- .navigation -->
  </nav><!-- nav -->
</template>

<script>
/* eslint no-undef: 0 */
import ChatStarter from '@/views/components/partials/ChatStarter'
import TopDown from '@/views/components/partials/TopDown'
import homeMixin from '@/mixins/homeMixin'
import LanguageSwitcher from '@/views/components/partials/LanguageSwitcher'
import ToggleFullscreen from '@/views/components/partials/ToggleFullscreen'
import MenuIcon from '@/views/components/partials/MenuIcon'

export default {
  name: 'Navigation',

  data () {
    return {
      user: this.$store.getters['auth/user']
    }
  },

  components: {
    LanguageSwitcher,
    ChatStarter,
    ToggleFullscreen,
    MenuIcon,
    TopDown
  },

  mounted () {
    this.$nextTick(() => {
      $('.sidenav').sidenav()
      $('.tooltipped').tooltip()
      $('.dropdown-trigger').dropdown()
    })
    // console.log('Navigation USER ROLE:')
    // console.log(this.userRole)
  },

  methods: {
    logout () {
      // console.log('LOGOUT');
      this.$store.dispatch('auth/logout')
    },
    caretaker () {
      alert('Necessary agreement of property Landlord!')
    },
    setRegisterUserRole (role) {
      this.$store.dispatch('auth/setRegisterUserRole', role)
    }
  },

  computed: {
    authenticated () {
      return !!this.$store.getters['auth/authenticated']
    },
    user_role () {
      return this.$store.getters['auth/userRole']
    },
    chat_link () {
      return this.$i18n.locale + '/chat'
    }
  },
  mixins: [homeMixin]
}
</script>

<style scoped>
nav{
  position: relative;
}
nav .brand-logo {
  left: 2vw;
}
li.left {
  margin-right: 7px;
}
.language-switcher{
  float: right;
}
ul li .user-view a i{
  height: 64px;
  line-height: unset;
}

@media screen and (max-width: 600px) {
  nav .brand-logo{
    margin-left: 70px;
    font-size: 16px;
  }
}

@media screen and (min-width: 601px) {
  nav .brand-logo{
    margin-left: 100px;
    font-size: 24px;
  }
}

@media screen and (min-width: 768px) {
  nav .brand-logo{
    margin-left: 120px;
    font-size: 28px;
  }
}

@media screen and (min-width: 992px) {
  nav .brand-logo{
    margin-left: 0px;
    font-size: 32px;
  }
}

@media screen and (min-width: 1200px) {
  nav .brand-logo{
    margin-left: 0px;
    font-size: 36px;
  }
}
</style>
