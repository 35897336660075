
export const authenticated = (state) => state.authenticated

export const user = (state) => state.user

export const profile = (state) => state.profile

export const token = (state) => state.token

export const countries = (state) => state.countries

// eslint-disable-next-line
export const register_user_role = (state) => state.register_user_role

export const userRole = (state) => {
  if (state.profile === null) {
    return state.user_role
  }
  return state.profile.role
}

export const hirer = (state) => state.hirer

export const hirerProfile = (state) => state.hirer_profile

export const customerNumber = state => state.customerNumber
